
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import AnalyticsPage from './analytics-page/AnalyticsPage';
import SitesPage from './sites-page/SitesPage';
import SitesPageNew from './sites-page-new/SitesPageNew';
import React, { useState, useEffect, MouseEventHandler } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AcceptPrivacyPolicyOverlay from "./accept-privacy-policy-overlay/AcceptPrivacyPolicyOverlay";
import SettingsPage from "./settings-page/SettingsPage";
import { dependencyContainer } from "./core/dependency-container";
import AnalyticsSettingsPage from "./analytics-page/analytics-settings-page/AnalyticsSettingsPage";


interface User {
  user_id: string;
  email: string;
  privacy_policy_accepted: boolean;
}

const App = () => {
  //const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(true);

  const handleClose: any = () => {

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ privacy_policy_accepted: true }),
    };

    fetch('/api/users/me', requestOptions)
      .then( () => setPrivacyPolicyAccepted(true))
      .catch( (e) => console.log('Opsi. Error updating privacy_policy_accepted of user :( '));
  };

  useEffect(() => {
    getCurrentUserOrRedirectToLogin();
  }, []);

  const getCurrentUserOrRedirectToLogin = async () => {
    const requestOptions: RequestInit = {
      method: 'GET',
    };

    const response = await fetch('/api/users/me', requestOptions);
    if (!response.ok) {
      window.location.href = '/login';
    }

    const user = await response.json() as User;

    setEmail(user.email);
    setUserId(user.user_id);
    setPrivacyPolicyAccepted(user.privacy_policy_accepted);
  }

  if (!privacyPolicyAccepted) {
    return   (
      <div>
        {<AcceptPrivacyPolicyOverlay onClose={handleClose} />}
      </div>
    );
  }

  ;

  return (
    <Router>
      <nav className="header">
        <div className="center-container">
          <a href="/" className="logo">privatracker.com</a>
          <div className="header-right">
            <a href="/settings">{email}</a>
            <a href="/logout">Logout</a>
          </div>
        </div>
      </nav>


      <main>
        <Routes>
          <Route path="/sites" element={<SitesPage i18n={dependencyContainer.i18n} />} />
          <Route path="/sites/new" element={<SitesPageNew i18n={dependencyContainer.i18n}/>} />
          <Route path="/sites/:siteName" element={<AnalyticsPage i18n={dependencyContainer.i18n}/>} />
          <Route path="/sites/:siteId/settings" element={<AnalyticsSettingsPage i18n={dependencyContainer.i18n}/>} />
          <Route path="/settings" element={<SettingsPage i18n={dependencyContainer.i18n}/>} />
          <Route path="/*" element={<Navigate replace to="/sites" />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;             
