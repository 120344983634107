import React, { useState, useEffect } from 'react';
import './AnalyticsSettingsPage.css';
import { i18nKeys } from '../../i18n/i18nKeys';
import { useParams } from 'react-router-dom';

interface IProps {
  i18n: i18nKeys
}

const AnalyticsSettingsPage = ({i18n}: IProps) => {
  const { siteId } = useParams();

  const [siteName, setSiteName] = useState('');
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [isSiteNamePopupOpen, setIsSiteNamePopupOpen] = useState(false);
  const [isCollaboratorPopupOpen, setIsCollaboratorPopupOpen] = useState(false);
  const [newSiteName, setNewSiteName] = useState('');
  const [newCollaboratorEmail, setNewCollaboratorEmail] = useState('');

  useEffect(() => {
    const fetchSiteSettings = async () => {
      try {
        const response = await fetch(`/api/sites/${siteId}/settings`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSiteName(data.siteName);
        setCollaborators(data.collaborators);
      } catch (error) {
        console.error('Failed to fetch site settings:', error);
      }
    };

    fetchSiteSettings();
  }, []);

  const handleEditSiteNameClick = () => {
    setNewSiteName(siteName);
    setIsSiteNamePopupOpen(true);
  };

  const handleSaveSiteName = async () => {
    try {
      const response = await fetch(`/api/sites/${siteId}/name`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ siteName: newSiteName }),
      });

      if (!response.ok) {
        throw new Error('Failed to update site name');
      }

      setSiteName(newSiteName);
      setIsSiteNamePopupOpen(false);
    } catch (error) {
      console.error('Failed to save new site name:', error);
    }
  };

  const handleAddCollaboratorClick = () => {
    setIsCollaboratorPopupOpen(true);
  };

  const handleAddCollaborator = async () => {
    if (!newCollaboratorEmail) return;

    try {
      const response = await fetch(`/api/sites/${siteId}/collaborators`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newCollaboratorEmail }),
      });

      if (!response.ok) {
        throw new Error('Failed to add collaborator');
      }

      setCollaborators((prev) => [...prev, newCollaboratorEmail]);
      setNewCollaboratorEmail(''); // Clear the input field
      setIsCollaboratorPopupOpen(false);
    } catch (error) {
      console.error('Failed to add collaborator:', error);
    }
  };

  const handleRemoveCollaborator = async (email: string) => {
    try {
      const response = await fetch(`/api/sites/${siteId}/collaborators/${email}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to remove collaborator');
      }

      setCollaborators((prev) => prev.filter(collaborator => collaborator !== email));
    } catch (error) {
      console.error('Failed to remove collaborator:', error);
    }
  };

  return (
    <section>
      <h1>Site Settings</h1>

      <h2>Basics</h2>
      <b>Name: </b> <a>{siteName}</a> <sup onClick={handleEditSiteNameClick} style={{ cursor: 'pointer' }}>🖊</sup>

      <h2>Collaborators <sup onClick={handleAddCollaboratorClick} style={{ cursor: 'pointer' }}>+</sup></h2>
      <ul>
        {collaborators.map((collaborator, index) => (
          <li key={index}>
            {collaborator} <sup onClick={() => handleRemoveCollaborator(collaborator)} style={{ cursor: 'pointer' }}>❌</sup>
          </li>
        ))}
      </ul>

      {isSiteNamePopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h3>Edit Site Name</h3>
            <input
              type="text"
              value={newSiteName}
              onChange={(e) => setNewSiteName(e.target.value)}
            />
            <button onClick={handleSaveSiteName}>Save</button>
            <button onClick={() => setIsSiteNamePopupOpen(false)}>Cancel</button>
          </div>
        </div>
      )}

      {isCollaboratorPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h3>Add Collaborator</h3>
            <input
              type="text"
              value={newCollaboratorEmail}
              onChange={(e) => setNewCollaboratorEmail(e.target.value)}
            />
            <button onClick={handleAddCollaborator}>Add</button>
            <button onClick={() => setIsCollaboratorPopupOpen(false)}>Cancel</button>
          </div>
        </div>
      )}

    </section>
  );
};

export default AnalyticsSettingsPage;
