


export const createQueryParametersForBackendApiCall = (urlWithoutQueryParameters: string, searchParams: URLSearchParams) => {

    let url = urlWithoutQueryParameters;
    // range will always be set
    searchParams.has('range') ? (url += `?range=${searchParams.get('range')}`) : (url += `?range=month`);
    
    // add all other query parameters if present
    searchParams.has('page') && (url += `&page=${searchParams.get('page')}`);
    searchParams.has('source') && (url += `&source=${searchParams.get('source')}`);
    searchParams.has('country') && (url += `&country=${searchParams.get('country')}`);
    searchParams.has('browser') && (url += `&browser=${searchParams.get('browser')}`);
    

    return url;
}

export const createUrlToAnalyticsSettingsPage = (siteId: string) => `/sites/${siteId}/settings`